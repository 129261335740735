import React from 'react';
import { FRONTEND_VERSION } from '../constants';

export function NavbarVersionButton(props) {
    const navbarContext = props.navbarContext;
    
    const backendVersion = navbarContext.state.backendVersion;
    const frontendVersion = FRONTEND_VERSION;

    return <button className="transparentButton"
                onClick={() => { navbarContext.handleModalShow();}} >
                {backendVersion} | {frontendVersion}
            </button>
}
