import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
    // we init with resources
    resources: {
        de: {
            translations: {
                'username': 'Benutzername',
                'username_cap': 'Benutzername',
                'password': 'Passwort',
                'password_cap': 'Passwort',
                'subscribe': 'Abonnieren',
                'register': 'Neu hier?',
                'register_long': 'Neuen Account erstellen',
                'unsubscribe': 'Deabbonieren',
                'login': 'Anmelden',
                'login_login': 'Login',
                'login_long': 'Bestehenden Account verwenden',
                'logout': 'Abmelden',
                'change.language': 'Sprache wechseln',
                'gift.card': 'Vorteilskarten',
                'table.card.number': 'Kartennummer',
                'table.card.number.short': 'Nummer',
                'table.card.name': 'Name',
                'table.card.balance': 'Guthaben',
                'table.card.state': 'Status',
                'table.card.activated': 'aktiviert',
                'table.card.deactivated': 'deaktiviert',
                'table.cards.connector': 'zu',
                'table.activity.date': 'Datum',
                'table.activity.card': 'Karte',
                'table.activity.amount': 'Betrag',
                'table.activity.place': 'Ort',
                'last.commit': 'Letzter Commit',
                'choose_username': 'Neuen Benutzernamen eingeben',
                'choose_password': 'Neues Passwort eingeben',
                'question_existing_account': 'In Besitz eines Görtz-Kontos?',
                'email': 'Email',
                'example_email': 'beispiel@mail.de',
                'enter_email': 'Email-Adresse zum Görtz-Konto eingeben',
                'enter_password': 'Passwort zum Görtz-Konto eingeben',
                'submit_registration': 'Jetzt Registrieren',
                'create_new_goertz_1': 'Neues Görtz-Konto',
                'create_new_goertz_2': ' anlegen',
                'here_word': 'hier',
                'modal.version.header': 'Versionsübersicht',
                'button.close': 'Schließen',
                'frontend.package.version': 'Frontend-Paket Version',
                'dash.greeting.welcomeBack': 'Willkommen zurück'
            }
        },
        en: {
            translations: {
                'username': 'username',
                'username_cap': 'Username',
                'password': 'password',
                'password_cap': 'Password',
                'subscribe': 'Subscribe',
                'register': 'Register',
                'register_long': 'Register new Account',
                'unsubscribe': 'Unsubscribe',
                'login': 'Login',
                'login_login': 'Login',
                'login_long': 'Login to existing Account',
                'logout': 'Logout',
                'change.language': 'Change Language',
                'gift.card': 'Gift Cards',
                'table.card.number': 'Card ID',
                'table.card.number.short': 'Card ID',
                'table.card.name': 'Name',
                'table.card.balance': 'Balance',
                'table.card.state': 'State',
                'table.card.activated': 'activated',
                'table.card.deactivated': 'deactivated',
                'table.cards.connector': 'to',
                'table.activity.date': 'Date',
                'table.activity.card': 'Card',
                'table.activity.amount': 'Amount',
                'table.activity.place': 'Place',
                'last.commit': 'Last Commit',
                'choose_username': 'Choose a username for your account',
                'choose_password': 'Choose a password for your account',
                'question_existing_account': 'Already have a Görtz Account?',
                'email': 'Email',
                'example_email': 'example@mail.de',
                'enter_email': 'Enter your Görtz-Email',
                'enter_password': 'Enter your Görtz-Password',
                'submit_registration': 'Submit Registration',
                'create_new_goertz_1': 'Create a new Görtz Account',
                'create_new_goertz_2': '',
                'here_word': 'here',
                'modal.version.header': 'Version Overview',
                'button.close': 'Close',
                'frontend.package.version': 'Frontend Package Version',
                'dash.greeting.welcomeBack': 'Welcome back'
            }
        }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
    },

    react: {
        wait: true
    }
});

export default i18n;