const BACKEND_SERVER_URL = valueOrFallback(process.env.REACT_APP_BACKEND_SERVER_URL, '/api')

export const API_LOGIN = BACKEND_SERVER_URL + '/user/login';
export const API_REFRESH_TOKENS = BACKEND_SERVER_URL + '/user/refreshTokens';
export const API_USERINFO = BACKEND_SERVER_URL + '/user/info';
export const API_URL_ABOUT = BACKEND_SERVER_URL + "/about";
export const API_URL_CARDS = BACKEND_SERVER_URL + "/cards";
export const API_URL_TOKEN = BACKEND_SERVER_URL + "/token";

export const PROJECT_URL_FRONTEND = 'https://github.com/AmberWorks/Goertz-React/commit/';
export const PROJECT_URL_BACKEND = 'https://github.com/AmberWorks/Goertz-Go/commit/';

export const FRONTEND_GIT_HASH = valueOrFallback(process.env.REACT_APP_GIT_SHA, undefined)
export const FRONTEND_VERSION = valueOrFallback(process.env.REACT_APP_VERSION, 'dev')

export const HTTP_STATUS_OK = 200;

export const LANG_KEY_EN = 'en';
export const LANG_KEY_DE = 'de';

function valueOrFallback(v, fb) {
    return v ? v : fb;
}

export function shortHash(hash, maxLen=8) {
    if (hash?.length >= maxLen) {
        return hash.slice(0, maxLen)
    }
    return hash;
}
