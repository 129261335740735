// TODO: Auth?

import { JwtService } from "./jwt.service";

export class ApiService {
    static async get(path) {
        return this.makeRequest(path, 'GET');
    }

    static async post(path, body) {
        return this.makeRequest(path, 'POST', body);
    }

    static async makeRequest(path, method, body) {
        await this.renewAtIfNeededAndPossible();

        const options = {
            method,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        if (JwtService.getAccessToken()) {
            options.headers['Authorization'] = 'Bearer ' + JwtService.getAccessToken();
        }
        return fetch(path, options);
    }

    static async makeRawRequest(path, method, token, body) {
        const options = {
            method,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        }
        if (token) {
            options.headers['Authorization'] = 'Bearer ' + token;
        }
        return fetch(path, options);
    }

    static async renewAtIfNeededAndPossible() {
        if (JwtService.getAccessToken()) {
            if (!JwtService.isJWTValid(JwtService.getAccessToken()) && JwtService.isJWTValid(JwtService.getRefreshToken())) {
                await JwtService.renewTokens();
            }
        }
    }
}
