import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Image, Form, Navbar } from "react-bootstrap";

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import 'bootstrap/dist/css/bootstrap.css';
import 'csshake/dist/csshake-horizontal.min.css'
import './Login.css';

import Logo from '../logo.svg';

import Authenticator from '../auth/Authenticator.js';
import { VersionModal } from '../versionDisplay/VersionModal';
import { NavbarVersionButton } from '../versionDisplay/NavbarVersionButton';

import {
    API_URL_ABOUT,
    LANG_KEY_EN,
    LANG_KEY_DE,
} from '../constants';

const ENTER_KEY = 'Enter';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameLogin: '',
            passwordLogin: '',
            usernameRegistration: '',
            passwordRegistration: '',
            emailGoertz: '',
            passwordGoertz: '',
            creation_mode: false,
            checkbox_checked: false,
            error: false,
            showModal: false
        };
    }

    componentDidMount() {
        fetch(API_URL_ABOUT, {
            method: 'GET'
        }).then(response => response.json())
            .then(json => {
                this.setState({ backendVersion: json.backend_version, backendGitHash: json.backend_git_hash });
            })
    }

    async handleLogin() {
        if (await Authenticator.login(this.state.usernameLogin, this.state.passwordLogin)) {
            this.props.history.push('/');
        }
        else {
            this.setState({ error: true });
            document.getElementById("unauthorized-feedback").className += " shake-horizontal shake-constant";
            setTimeout(() => {
                document.getElementById("unauthorized-feedback").className = "unauthorized-text";
            }, 200);
        }
    };

    async handleRegistration() {
        document.getElementById("no-registration-feedback").className += " shake-horizontal shake-constant";
        setTimeout(() => {
            document.getElementById("no-registration-feedback").className = "unauthorized-text";
        }, 200);
    }

    // handleClose function for Modal
    handleModalShow = () => this.setState({ showModal: true });


    // handleClose function for Modal
    handleModalClose = () => this.setState({ showModal: false });

    render() {
        const { backendGitHash } = this.state;
        return (
            <div className="Login">
                <header>
                    <Navbar variant='dark' fixed='top' className='navbar-red' expand='lg'>
                        <Navbar.Brand href='/login'>
                            Görtz {i18n.t('gift.card')}
                        </Navbar.Brand>
                        <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li className="nav-item active">
                                <small className="nav-link d-none d-sm-block">
                                    <NavbarVersionButton navbarContext={this} />
                                </small>
                            </li>
                        </ul>
                        {!this.state.creation_mode ?
                            <div>
                                <Button className="d-lg-none d-md-none d-block" variant='outline-light' onClick={(e) =>
                                    this.setState({ creation_mode: !this.state.creation_mode })
                                }>
                                    {i18n.t('register')}
                                </Button>
                                <Button className="d-none d-md-block d-lg-block" variant='outline-light' onClick={(e) =>
                                    this.setState({ creation_mode: !this.state.creation_mode })
                                }>
                                    {i18n.t('register_long')}
                                </Button>
                            </div>
                            :
                            <div>
                                <Button className="d-lg-none d-md-none d-block" variant='outline-light' onClick={(e) =>
                                    this.setState({ creation_mode: !this.state.creation_mode })
                                }>
                                    {i18n.t('login_login')}
                                </Button>
                                <Button className="d-none d-md-block d-lg-block" variant='outline-light' onClick={(e) =>
                                    this.setState({ creation_mode: !this.state.creation_mode })
                                }>
                                    {i18n.t('login_long')}
                                </Button>
                            </div>
                        }
                    </Navbar>
                    
                    <VersionModal backendGitHash={backendGitHash} navbarState={this} />

                </header>
                <main role="main" className="flex-shrink-0">
                    <div className="container-fluid">
                        {!this.state.creation_mode ?
                            <div className="wrapContainer">
                                <div className="wrapper fadeInDown">
                                    <div id="formContent">

                                        <div className="fadeIn first">
                                            <Image roundedCircle src={Logo} alt="Logo" />
                                        </div>

                                        <form>
                                            <input type="text" value={this.state.usernameLogin}
                                                onChange={(e) => {
                                                    this.setState({ usernameLogin: e.target.value });
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === ENTER_KEY)
                                                        this.handleLogin();
                                                }}
                                                id="username_login" className="fadeIn second" name="username" placeholder={i18n.t("username")} />
                                            <input type="password" value={this.state.passwordLogin}
                                                onChange={(e) => {
                                                    this.setState({ passwordLogin: e.target.value });
                                                }}
                                                onKeyPress={(e) => {
                                                    if (e.key === ENTER_KEY)
                                                        this.handleLogin();
                                                }}
                                                id="password_login" className="fadeIn third" name="password" placeholder={i18n.t("password")} />
                                            <Form.Control.Feedback id="unauthorized-feedback" className="unauthorized-text" type="invalid">
                                                Could not authorize.
                                            </Form.Control.Feedback>
                                            <Button
                                                onClick={() => {
                                                    this.handleLogin();
                                                }}
                                                block id="submitButton">
                                                {i18n.t("login")}
                                            </Button>
                                        </form>

                                    </div>
                                    <div id="languageLabel" onClick={(e) => {
                                        let languageToChangeTo = i18n.language === LANG_KEY_DE ? LANG_KEY_EN : LANG_KEY_DE;
                                        i18n.changeLanguage(languageToChangeTo);
                                    }}>
                                        {i18n.language === LANG_KEY_DE ? LANG_KEY_EN.toUpperCase() : LANG_KEY_DE.toUpperCase()}
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='form_wrapper'>
                                <Form className='form'>
                                    <Form.Group controlId='formBasicUsername'>
                                        <Form.Label>{i18n.t('username_cap')}</Form.Label>
                                        <Form.Control type='text' placeholder={i18n.t('username')} value={this.state.usernameRegistration} onChange={(e) => {
                                            this.setState({ usernameRegistration: e.target.value });
                                        }} />
                                        <Form.Text className='text-muted'>{i18n.t('choose_username')}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId='formBasicPassword'>
                                        <Form.Label>{i18n.t('password_cap')}</Form.Label>
                                        <Form.Control type='password' placeholder={i18n.t('password')} value={this.state.passwordRegistration} onChange={(e) => {
                                            this.setState({ passwordRegistration: e.target.value });
                                        }} />
                                        <Form.Text className='text-muted'>{i18n.t('choose_password')}</Form.Text>
                                    </Form.Group>
                                    <Form.Group controlId='formBasicCheckbox'>
                                        <Form.Check checked={this.state.checkbox_checked} type='checkbox' label={i18n.t('question_existing_account')} onChange={(e) => {
                                            this.setState({ checkbox_checked: e.target.checked })
                                        }} />
                                    </Form.Group>
                                    {this.state.checkbox_checked ?
                                        <div>
                                            <Form.Group controlId='formBasicEmail'>
                                                <Form.Label>{i18n.t('email')}</Form.Label>
                                                <Form.Control type='email' placeholder={i18n.t('example_email')} value={this.state.emailGoertz} onChange={(e) => {
                                                    this.setState({ emailGoertz: e.target.value });
                                                }} />
                                                <Form.Text className='text-muted'>{i18n.t('enter_email')}</Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId='formBasicPassword'>
                                                <Form.Label>{i18n.t('password_cap')}</Form.Label>
                                                <Form.Control type='password' placeholder={i18n.t('password')} value={this.state.passwordGoertz} onChange={(e) => {
                                                    this.setState({ passwordGoertz: e.target.value });
                                                }} />
                                                <Form.Text className='text-muted'>{i18n.t('enter_password')}</Form.Text>
                                            </Form.Group>
                                        </div>
                                        :
                                        <Form.Group controlId='formGoertzLink'>
                                            <Form.Label>
                                                {i18n.t('create_new_goertz_1')} <a href='http://www.goertz-vorteilskarten.de' target='blank'>{i18n.t('here_word')}</a>{i18n.t('create_new_goertz_2')}
                                            </Form.Label>
                                        </Form.Group>
                                    }
                                    <Form.Control.Feedback id="no-registration-feedback" className="unauthorized-text" type="invalid">
                                        Registration Failed.
                                    </Form.Control.Feedback>
                                    <div className="buttonWrapper">
                                        <Button variant='primary' onClick={() => {
                                            this.handleRegistration();
                                        }}>
                                            {i18n.t('submit_registration')}
                                        </Button>
                                    </div>
                                </Form>
                                <div id="languageLabel" onClick={(e) => {
                                    let languageToChangeTo = i18n.language === LANG_KEY_DE ? LANG_KEY_EN : LANG_KEY_DE;
                                    i18n.changeLanguage(languageToChangeTo);
                                }}>
                                    {i18n.language === LANG_KEY_DE ? LANG_KEY_EN.toUpperCase() : LANG_KEY_DE.toUpperCase()}
                                </div>
                            </div>
                        }
                    </div>
                </main>
            </div >
        );
    }
}

const Extended = withTranslation()(Login);
Extended.static = Login.static;

export default withRouter(Extended);

