import React from 'react';
import { DropdownButton, Dropdown, Image, Navbar } from "react-bootstrap";
import { withRouter } from 'react-router-dom';

import i18n from "i18next";

import { VersionModal } from '../versionDisplay/VersionModal'
import { NavbarVersionButton } from '../versionDisplay/NavbarVersionButton'
import Authenticator from '../auth/Authenticator';

import 'bootstrap/dist/css/bootstrap.css';
import './Navbar_Dashboard.css';

import flag_germany from '../img/germany.jpg'
import flag_england from '../img/england.jpg'

import { 
    API_URL_ABOUT,
    LANG_KEY_DE,
    LANG_KEY_EN,
} from "../constants";

// const SUBSCRIBE_KEY = 'subscribe';
// const UNSUBSCRIBE_KEY = 'unsubscribe';
// const BLOCKED_KEY = 'blocked';

class NavbarDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // subscribeButtonText: SUBSCRIBE_KEY,
            isGermanLanguage: false,
            showModal: false
        };
    };


    async handleLogout() {
        Authenticator.logout();
        this.props.history.push('/login');
    };


    /*     async handleSubscriptionButtonClick() {
            if (this.state.subscribeButtonText === SUBSCRIBE_KEY) {
                navigator.serviceWorker.ready
                    .then(async (registration) => {
                        // Get the server's public key
                        const response = await fetch('/api/push/vapidPublicKey');
                        const vapidPublicKey = (await response.json()).publicKey;
                        console.log(`VAPID public key from backend server; ${vapidPublicKey}`);
                        // Subscribe the user
                        return registration.pushManager.subscribe({
                            userVisibleOnly: true,
                            // Chrome doesn't accept the base64-encoded (string) vapidPublicKey yet
                            // urlBase64ToUint8Array() is defined in /tools.js
                            applicationServerKey: urlBase64ToUint8Array(vapidPublicKey)
                        });
                    }).then((subscription) => {
                        console.log('Subscribed', subscription.endpoint);
                        return fetch('/api/push/register', {
                            method: 'post',
                            headers: {
                                'Content-type': 'application/json'
                            },
                            body: JSON.stringify({
                                subscription: subscription
                            })
                        });
                    }).then(this.setState({ subscribeButtonText: UNSUBSCRIBE_KEY }), (err) => {
                        console.log('User or server blocked us from showing notifications.', err);
                        this.setState({ subscribeButtonText: Notification.permission === 'denied' ? BLOCKED_KEY : SUBSCRIBE_KEY })
                        return;
                    });
            } else if (this.state.subscribeButtonText === UNSUBSCRIBE_KEY) {
                navigator.serviceWorker.ready
                    .then((registration) => {
                        return registration.pushManager.getSubscription();
                    }).then((subscription) => {
                        return subscription.unsubscribe()
                            .then(() => {
                                console.log('Unsubscribed', subscription.endpoint);
                                return fetch('/api/push/unregister', {
                                    method: 'post',
                                    headers: {
                                        'Content-type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        subscription: subscription
                                    })
                                });
                            });
                    }).then(this.setState({ subscribeButtonText: SUBSCRIBE_KEY }), (err) => {
                        console.log('Error unsubscribing.');
                    });
            }
            // TODO: Show Toast how to enable Notifications when blocked by user earlier with else here
        }; */


    onChangeLanguage() {
        if (i18n.language === LANG_KEY_DE) {
            i18n.changeLanguage(LANG_KEY_EN, (err, t) => {
                this.setState({ isGermanLanguage: false });
            });
        }
        else {
            i18n.changeLanguage(LANG_KEY_DE, (err, t) => {
                this.setState({ isGermanLanguage: true });
            });
        }
    }


    componentDidMount() {
        navigator.serviceWorker.ready
            .then((registration) => {
                return registration.pushManager.getSubscription();
            }).then((subscription) => {
                if (i18n.language.includes(LANG_KEY_DE)) {
                    i18n.changeLanguage(LANG_KEY_DE, (err, t) => {
                        this.setState({ isGermanLanguage: true });
                    });
                } else {
                    this.setState({ isGermanLanguage: false });
                }
                //this.setState({ isGermanLanguage: i18n.language.includes(LANG_DE_KEY) ? true : false });
                //this.setState({ subscribeButtonText: subscription ? UNSUBSCRIBE_KEY : SUBSCRIBE_KEY });
            });

        fetch(API_URL_ABOUT, {
            method: 'GET'
        }).then(response => response.json())
            .then(json => {
                this.setState({ backendVersion: json.backend_version, backendGitHash: json.backend_git_hash });
            })
    }


    sortByDate(activityA, activityB) {
        // Sort given Dates by latest first
        return new Date(activityB.date).getTime() - new Date(activityA.date).getTime();
    }


    // handleClose function for Modal
    handleModalShow = () => this.setState({ showModal: true });


    // handleClose function for Modal
    handleModalClose = () => this.setState({ showModal: false });


    render() {
        const { backendGitHash } = this.state;
        return (
            <div className="NavbarDashboard">
                <Navbar variant='dark' fixed='top' className='dunkelrot' expand='lg'>
                    <Navbar.Brand href='/'>
                        Görtz {i18n.t('gift.card')}
                    </Navbar.Brand>
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        <li className="nav-item active">
                            <small className="nav-link d-none d-sm-block">
                                <NavbarVersionButton navbarContext={this} />
                            </small>
                        </li>
                    </ul>
                    <DropdownButton alignRight variant="outline-light" id="dropdown-item-button" title="">
                        {/*
                        <Dropdown.Item as="button" id="subscriptionButton" 
                            onClick={() => {this.handleSubscriptionButtonClick();}} >
                            {i18n.t(this.state.subscribeButtonText)}
                        </Dropdown.Item>
                        */}

                        <Dropdown.Item as="button" onClick={() => {
                            this.handleLogout();
                        }}>{i18n.t("logout")}</Dropdown.Item>

                        <Dropdown.Item as="button" className="languageButton"
                            onClick={() => {this.onChangeLanguage();}} >
                            {
                                this.state.isGermanLanguage
                                ? (<Image src={flag_england} id="languageImage" rounded></Image>)
                                : (<Image src={flag_germany} id="languageImage" rounded></Image>)
                            }
                        </Dropdown.Item>
                    </DropdownButton>
                    <section className="subscription-details js-subscription-details is-invisible">
                        <pre><code className="js-subscription-json"></code></pre>
                    </section>
                </Navbar>

                <VersionModal backendGitHash={backendGitHash} navbarState={this} />
            </div>
        );
    }
}

export default withRouter(NavbarDashboard);
