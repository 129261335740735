import { ApiService, JwtService } from '../_services/';
import { API_USERINFO } from '../constants'

class Authenticator {
    isAuthenticated = false;
    userInfo = undefined;

    /**
     * Load Login auth from localStorage and check if accepted by backend
     *
     * @param {function} cb optional callback
     */
    static async refreshState(cb) {
        if (JwtService.isJWTValid(JwtService.getAccessToken()) || JwtService.isJWTValid(JwtService.getRefreshToken())) {
            this.isAuthenticated = true;
        } else {
            await this.logout();
        }

        if (!this.userInfo) {
            this.userInfo = (await (await ApiService.get(API_USERINFO)).json()).user;
        }

        if (typeof cb === 'function') { // Callback, if set
            cb();
        }
    }

    /**
     * Perform Login and store authentication
     *
     * @param {*} user username
     * @param {*} pass password
     */
    static async login(user, pass) {
        let res = await JwtService.loginUser(user, pass);
        if (res) {
            this.isAuthenticated = true;
            this.userInfo = (await (await ApiService.get(API_USERINFO)).json()).user;
        } else {
            await this.logout();
        }
        return res;
    }

    static async logout() {
        if (await JwtService.destroyTokens()) {
            this.isAuthenticated = false;
            this.userInfo = {};
        }
    }
};

export default Authenticator;
