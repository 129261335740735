import {
    HTTP_STATUS_OK,
    API_LOGIN,
    API_REFRESH_TOKENS,
} from '../constants';
import {ApiService} from './api.service';

const STORE_ACCESS_TOKEN = 'Goertz-AT';
const STORE_REFRESH_TOKEN = 'Goertz-RT';

export class JwtService {
    static getAccessToken() {
        return this.getNullSafe(localStorage.getItem(STORE_ACCESS_TOKEN));
    }
  
    static getRefreshToken() {
        return this.getNullSafe(localStorage.getItem(STORE_REFRESH_TOKEN));
    }

    // TODO: Eval, if needed (!null === !undefined ?)
    static getNullSafe(t) {
        if (t === null) {
            return undefined;
        }
        return t
    }
  
    static saveTokens(accessToken, refreshToken) {
        localStorage.setItem(STORE_ACCESS_TOKEN, accessToken);
        localStorage.setItem(STORE_REFRESH_TOKEN, refreshToken);
    }
  
    static destroyTokens() {
        localStorage.removeItem(STORE_ACCESS_TOKEN);
        localStorage.removeItem(STORE_REFRESH_TOKEN);
    }

    static decodeJWT(tokenStringBase64) {
        // Parse token to JSON
        var base64Url = tokenStringBase64.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    static async processTokenResponse(response) {
        return response.then(async res => {
            if (res.status === HTTP_STATUS_OK) {
                return await res.json().then(token => {
                    JwtService.saveTokens(token.accessToken, token.refreshToken)
                    return true;
                })
            }
            return false;
        }).then(success => {
            console.log("Have login and saving tokens been successful? " + success);
            return success;
        })
    }
    
    static async loginUser(username, password) {
        return await this.processTokenResponse(ApiService.post(API_LOGIN, {username, password}));
    }
    
    static async renewTokens() {
        // Must use makeRequest instead of post, else call stack circles
        return await this.processTokenResponse(ApiService.makeRawRequest(API_REFRESH_TOKENS, 'POST', undefined,
            {refresh_token: JwtService.getRefreshToken()}
        ));
    }

    static isJWTValid(jwt) {
        if (!jwt) {
            return false;
        }
        const token = this.decodeJWT(jwt);
        const currentTime = new Date();
        return token.exp > currentTime.getTime() / 1000;
    }
}
