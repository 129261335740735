import React from 'react';
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import i18n from "i18next";

import {
    PROJECT_URL_FRONTEND, 
    PROJECT_URL_BACKEND, 
    FRONTEND_VERSION, 
    FRONTEND_GIT_HASH,
    shortHash,
} from "../constants";

export function VersionModal(props) {
    const navbarState = props.navbarState;

    const backendVersion = navbarState.state.backendVersion;
    const backendHash = props.backendGitHash;
    
    const frontendVersion = FRONTEND_VERSION;
    const frontendHash = FRONTEND_GIT_HASH;

    return <Modal show={navbarState.state.showModal} onHide={navbarState.handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{i18n.t("modal.version.header")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <h5>Backend</h5>
                        <Row className="show-grid">
                            <Col sm={7}>Version:</Col>
                            <Col sm={5}>{backendVersion}</Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={7}>{i18n.t("last.commit")}:</Col>
                            <Col sm={5}>
                                <a href={PROJECT_URL_BACKEND + backendHash}
                                    rel="noopener noreferrer"
                                    target="_blank" >
                                    {shortHash(backendHash)}
                                </a>
                            </Col>
                        </Row>
                    </Container>
                    <br />
                    <Container>
                        <h5>Frontend</h5>
                        <Row className="show-grid">
                            <Col sm={7}>Version:</Col>
                            <Col sm={5}>{frontendVersion}</Col>
                        </Row>
                        <Row className="show-grid">
                            <Col sm={7}>{i18n.t("last.commit")}:</Col>
                            <Col sm={5}>
                                <a href={PROJECT_URL_FRONTEND + frontendHash}
                                    rel="noopener noreferrer"
                                    target="_blank" >
                                    {shortHash(frontendHash)}
                                </a>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={navbarState.handleModalClose}>
                        {i18n.t("button.close")}
                    </Button>
                </Modal.Footer>
            </Modal>
    ;
}
