import React from 'react';
import { withRouter } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import PullToRefresh from 'react-simple-pull-to-refresh';

import 'bootstrap/dist/css/bootstrap.css';
import './Dashboard.css';

import NavbarDashboard from '../navbar/Navbar_Dashboard';
import Authenticator from '../auth/Authenticator';

import {
    API_URL_CARDS,
    LANG_KEY_DE,
    LANG_KEY_EN,
} from '../constants';

import {ApiService} from '../_services';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isCardsLoading: true,
            isActivitiesLoading: true,
            isLastUpdatedLoading: true
        };
    };

    componentDidMount() {
        // Request all Cards wait for response before calling ActivityFunction
        ApiService.get(API_URL_CARDS)
        // TODO: See https://gitlab.com/LucaBernstein/goertz-go/issues/4#note_264944329
        .then(response => {
            if (response.status === 401) { 
                // Logout if API returns 401 -> token not valid anymore
                return Authenticator.logout();
            } else if (response.status >= 500) {
                console.log("Unexpected status code returned: " + response.status);
                return false;
            } else {
                // Go on if API returns 200
                return response.json()
                    .then(json => {
                        let map = new Map();
                        for (let i = 0; i < json.cards.length; i++) {
                            map.set(json.cards[i].cardId, json.cards[i].name);
                        }
                        let lastUpdatedDict = this.getLastUpdatedDict(json.ageDataMinutes);
                        this.setState({ isCardsLoading: false, cardsData: json.cards, cardMap: map, isLastUpdatedLoading: false, lastUpdatedDict: lastUpdatedDict });
                    })
                    .then(reply => {
                        this.getAllCardsActivities();
                    });
            }
        })
    }


    // TODO: add Test
    getLastUpdatedDict(ageDataMinutes) {
        // Unfortunately, doesn't work with i18n because not being in render-Function -> return german AND english version instead
        // This is a workaround, but has it's advantages when it comes to 'Satzstellung' ;)
        let currentTime = new Date();
        let startOfDay = new Date();
        startOfDay.setHours(0, 0, 0, 0);
        let pastMinutesOfCurrentDay = (currentTime - startOfDay) / (1000 * 60);

        if (ageDataMinutes === 0) {
            return {
                'en': 'Updated just now',
                'de': 'Gerade eben aktualisiert'
            };
        } else if (ageDataMinutes > 0 && ageDataMinutes <= pastMinutesOfCurrentDay && ageDataMinutes < 60) {
            return {
                'en': 'Updated ' + ageDataMinutes + (ageDataMinutes === 1 ? ' minute' : ' minutes') + ' ago',
                'de': 'Vor ' + ageDataMinutes + (ageDataMinutes === 1 ? ' Minute' : ' Minuten') + ' aktualisiert',
            };
        } else if (ageDataMinutes >= 60 && ageDataMinutes <= pastMinutesOfCurrentDay && ageDataMinutes < 1440) {
            let numberOfFullHours = Math.floor(ageDataMinutes / 60);
            return {
                'en': 'Updated ' + numberOfFullHours + (numberOfFullHours === 1 ? ' hour' : ' hours') + ' ago',
                'de': 'Vor ' + numberOfFullHours + (numberOfFullHours === 1 ? ' Stunde' : ' Stunden') + ' aktualisiert'
            };
        } else if (ageDataMinutes > pastMinutesOfCurrentDay && ageDataMinutes <= pastMinutesOfCurrentDay + 1440) {
            let timeOfUpdate = new Date(currentTime - ageDataMinutes * 60 * 1000);
            return {
                'en': 'Updated yesterday at ' + timeOfUpdate.toLocaleTimeString(LANG_KEY_EN, { hour: "2-digit", minute: "2-digit" }),
                'de': 'Gestern um ' + timeOfUpdate.toLocaleTimeString(LANG_KEY_DE, { hour: "2-digit", minute: "2-digit" }) + ' aktualisiert'
            };
        } else if (ageDataMinutes > pastMinutesOfCurrentDay) {
            let timeOfUpdate = new Date(currentTime - ageDataMinutes * 60 * 1000);
            return {
                'en': 'Updated on ' + timeOfUpdate.toLocaleDateString(LANG_KEY_EN, { year: "2-digit", month: "2-digit", day: "2-digit" }) + ' at ' + timeOfUpdate.toLocaleTimeString(LANG_KEY_EN, { hour: "2-digit", minute: "2-digit" }),
                'de': 'Am ' + timeOfUpdate.toLocaleDateString(LANG_KEY_DE, { year: "2-digit", month: "2-digit", day: "2-digit" }) + ' um ' + timeOfUpdate.toLocaleTimeString(LANG_KEY_DE, { hour: "2-digit", minute: "2-digit" }) + ' aktualisiert'
            };
        } else {
            return {
                'en': 'Updated at unknown time',
                'de': 'Zu unbestimmter Zeit aktualisiert'
            };
        }
    }


    sortByDate(activityA, activityB) {
        // Sort given Dates by latest first
        return new Date(activityB.date).getTime() - new Date(activityA.date).getTime();
    }


    getAllCardsActivities() {
        let { cardsData } = this.state;
        let ActivitiesJSON = [];
        let promises = [];

        // Request all Activitites for each card and push to JSON
        for (let j = 0; j < cardsData.length; j++) {
            let card = cardsData[j];

            promises.push(
                ApiService.get(API_URL_CARDS + '/' + card.cardId + '?limit=10')
                .then(response => {
                    if (response.status === 200) {
                        return response.json();
                    } else {
                        console.log("Requesting card unsuccessful: " + response.status);
                        return undefined;
                    }
                })
                .then(json => {
                    if (json) {
                        JSON.parse(json.historyEntries).forEach(e => {
                            e.selfId = card.cardId;
                            ActivitiesJSON.push(JSON.parse(JSON.stringify(e)));
                        })
                    }
                })
            )
        }

        // Wait for all responses before sorting JSON by Date and reducing to 10 entries
        Promise.all(promises)
            .then(() => {
                ActivitiesJSON.sort(this.sortByDate);
                this.setState({ isActivitiesLoading: false, activitiesData: ActivitiesJSON.slice(0, 10) });
            })
    }


    createCardsTable = cardsData => {
        let cardTable = [];

        // Create a table from the given cardsDataJSON
        for (let i = 0; i < cardsData.length; i++) {
            let row = [];
            let balance = parseFloat(Math.round(cardsData[i].balance) / 100).toFixed(2);

            row.push(<td nowrap="true" key="1">{cardsData[i].ean}</td>);
            row.push(<td nowrap="true" key="2">{cardsData[i].name}</td>);
            row.push(<td nowrap="true" key="3"><div className="alignLeft"><b className="alignRight">{balance}€</b></div></td>);

            if (cardsData[i].locked === false) {
                row.push(<td nowrap="true" className="d-none d-sm-block" key="4">{i18n.t('table.card.activated')}</td>);
                cardTable.push(<tr key={i}>{row}</tr>);
            }
            else {
                row.push(<td nowrap="true" className="d-none d-sm-block" key="5">{i18n.t('table.card.deactivated')}</td>);
                cardTable.push(<tr className="graueSchrift" key={i}>{row}</tr>);
            }
        }
        return cardTable;
    }


    getCardNameFromCardId(cardId) {
        let { cardMap } = this.state;
        return cardMap.get(cardId);
    }


    createActivitiesTable = activitiesData => {
        let activitiesTable = [];

        // Create a table from the given activitiesDataJSON
        for (let i = 0; i < activitiesData.length; i++) {
            let row = [];
            let date_display = new Date(activitiesData[i].date);
            let card_name = this.getCardNameFromCardId(activitiesData[i].selfId);
            let amount = parseFloat(Math.round(activitiesData[i].amount) / 100).toFixed(2);

            row.push(<td nowrap="true" key="1">{date_display.toLocaleDateString(LANG_KEY_DE, { year: "numeric", month: "2-digit", day: "2-digit" })}</td>);
            row.push(<td nowrap="true" key="2">{card_name}</td>);

            if (activitiesData[i].type !== 'transfer') {
                // Bezahlung (payment) oder Aufladung (load)
                if (activitiesData[i].type === 'payment') {
                    row.push(<td nowrap="true" className="rot" key="3"><div className="alignLeft"><b className="alignRight">{amount}€</b></div></td>);
                } else {
                    row.push(<td nowrap="true" className="grün" key="3"><div className="alignLeft"><b className="alignRight">{amount}€</b></div></td>);
                }
                row.push(<td nowrap="true" className="d-none d-sm-block" key="4">{activitiesData[i].place}</td>);
            }
            else {
                // Übertragung (transfer)
                row.push(<td nowrap="true" key="3"><div className="alignLeft"><b className="alignRight">{amount}€</b></div></td>);
                row.push(<td nowrap="true" className="d-none d-sm-block" key="4">Umbuchung</td>);
            }
            activitiesTable.push(<tr key={i}>{row}</tr>);
        }
        return activitiesTable;
    };

    getGreetingFirstname() {
        return Authenticator.userInfo.firstname;
    }

    handleRefresh() {
        window.location.reload(false);
    }

    render() {
        const { 
            isActivitiesLoading,
            activitiesData,
            isCardsLoading,
            cardsData,
            isLastUpdatedLoading,
            lastUpdatedDict
        } = this.state;
        return (
            <div className="Dashboard">
                <header>
                    <NavbarDashboard></NavbarDashboard>
                </header>
                <main role="main" className="flex-shrink-0">
                    <div className="container-fluid">
                        <PullToRefresh onRefresh={this.handleRefresh}>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="table-responsive">
                                        { this.getGreetingFirstname() ? <h4>{i18n.t("dash.greeting.welcomeBack")}, {this.getGreetingFirstname()}!</h4> : <div /> }
                                        <table className="table table-borderless table-striped">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col" className="d-sm-none d-block">{i18n.t("table.card.number.short")}</th>
                                                    <th scope="col" className="d-none d-sm-block">{i18n.t("table.card.number")}</th>
                                                    <th scope="col">{i18n.t("table.card.name")}</th>
                                                    <th scope="col">{i18n.t("table.card.balance")}</th>
                                                    <th className="d-none d-sm-block" scope="col">{i18n.t("table.card.state")}</th>
                                                </tr>
                                            </thead>
                                                {isCardsLoading ?
                                                    <tbody></tbody>
                                                    :
                                                    <tbody>
                                                        {this.createCardsTable(cardsData)}
                                                    </tbody>
                                                }
                                        </table>
                                    </div>
                                    {isCardsLoading ?
                                        <Spinner className="spinner" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <div className="table-responsive">
                                        <table className="table table-borderless table-striped">
                                            <thead className="">
                                                <tr>
                                                    <th scope="col">{i18n.t("table.activity.date")}</th>
                                                    <th scope="col">{i18n.t("table.activity.card")}</th>
                                                    <th scope="col">{i18n.t("table.activity.amount")}</th>
                                                    <th className="d-none d-sm-block" scope="col">{i18n.t("table.activity.place")}</th>
                                                </tr>
                                            </thead>
                                            {isActivitiesLoading ?
                                                <tbody></tbody>
                                                :
                                                <tbody>
                                                    {this.createActivitiesTable(activitiesData)}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                    {isActivitiesLoading ?
                                        <Spinner className="spinner" animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                        :
                                        <div></div>
                                    }
                                </div>
                            </div>
                            {isLastUpdatedLoading ? <p></p> : <p className="floatLeft">{i18n.language.includes(LANG_KEY_EN) ? lastUpdatedDict.en : lastUpdatedDict.de}</p>}
                        </PullToRefresh>
                    </div>
                </main>
            </div >
        );
    }
}

const Extended = withTranslation()(Dashboard);
Extended.static = Dashboard.static;

export default withRouter(Extended);
