import React from 'react';
import { Redirect, Route } from 'react-router';
import Authenticator from '../auth/Authenticator';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                Authenticator.isAuthenticated ? (
                    <Component {...props} />
                ) : (
                        <Redirect
                            to="/login"
                        />
                    )
            }
        />
    )
}